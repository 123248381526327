import { FC } from "react";
import { Helmet } from "react-helmet";
import styles from "./dev.module.scss";

const Dev: FC = () => {
  return (
    <div className={styles.dev}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zoratopia Photo Booth</title>
        <script type="text/javascript" src="./screenfull.min.js"></script>
        <script src="./p5.min.js"></script>
        <script src="./p5.dom.min.js"></script>
        <script src="./clmtrackr.min.js"></script>
        <script src="./sketch.js"></script>
      </Helmet>
    </div>
  );
};

export default Dev;

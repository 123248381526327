import React, { FC, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { submit } from "../../client/firebase";
import styles from "./booth.module.scss";

export enum AppState {
  EnteringNumber = "entering-number",
  Previewing = "previewing",
  Capturing = "capturing",
  Reviewing = "reviewing",
  Uploading = "uploading",
  Done = "done",
}

const scale = 0.81;

const Booth: FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fixedIndexParam = urlParams.get("shader");

  const startTimeRef = useRef(new Date());
  const containerRef = useRef<HTMLDivElement>(null);
  const flashRef = useRef<HTMLDivElement>(null);
  const [appState, setAppState] = useState(
    fixedIndexParam && fixedIndexParam.length > 0
      ? AppState.Previewing
      : AppState.EnteringNumber
  );
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [numberIsValid, setNumberIsValid] = useState(false);
  const [captures, setCaptures] = useState<string[]>([]);
  const [reviewFrame, setReviewFrame] = useState(0);

  // document.elementsFromPoint

  useEffect(() => {
    let isMounted = true;

    const update = () => {
      if (containerRef.current) {
        const time =
          (new Date().getTime() - startTimeRef.current.getTime()) / 1000;

        containerRef.current.style.setProperty(
          "--gradient",
          `linear-gradient(${time * 50}deg, rgb(${
            Math.abs(Math.sin(time)) * 255
          }, 255, ${1 - Math.abs(Math.cos(time)) * 255}), rgb(255, ${
            1 - Math.abs(Math.cos(time)) * 255
          }, ${Math.abs(Math.sin(time)) * 255}), rgb(${
            1 - Math.abs(Math.sin(time)) * 255
          }, 0, ${Math.abs(Math.cos(time)) * 255}))`
        );
        containerRef.current.style.setProperty(
          "--highlight",
          `rgba(${Math.abs(Math.cos(time)) * 255},${
            Math.abs(Math.cos(time * -0.5)) * 255
          },${Math.abs(Math.sin(time)) * 255}, 0.75)`
        );

        const elapsedInState =
          (new Date().getTime() - window.CYCLE_START.getTime()) / 1000;
        const previewDuration = window.SHADER_COUNT * window.PREVIEW_TIME;

        containerRef.current.style.setProperty(
          "--elapsed",
          `${((elapsedInState % previewDuration) / previewDuration) * 100}vw`
        );
      }
      if (isMounted) {
        requestAnimationFrame(update);
      }
    };
    update();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const isValid =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        phoneNumber
      );
    setNumberIsValid(isValid);
  }, [phoneNumber]);

  useEffect(() => {
    window.APP_STATE = appState;
    window.CYCLE_START = new Date();

    if (appState === AppState.Capturing) {
      const canvas = document.querySelector("canvas");
      console.log({ canvas });
      if (!canvas) return;

      const _captures: string[] = [];
      const captureFrame = () => {
        flashRef.current!.style.backgroundColor = "white";
        if (_captures.length >= window.SHADER_COUNT) {
          setCaptures(_captures);
          clearInterval(captureInterval);
          setAppState(AppState.Reviewing);
          console.log("Done!", _captures);
        } else {
          setTimeout(() => {
            flashRef.current?.setAttribute("data-state", "visible");
          }, window.PREVIEW_TIME * 1000 - 850);
          setTimeout(() => {
            const data = canvas.toDataURL("image/png");
            console.log("Got capture ", _captures.length);
            _captures.push(data);
            flashRef.current?.setAttribute("data-state", "hidden");
          }, window.PREVIEW_TIME * 1000 - 600);
        }
      };
      const captureInterval = setInterval(
        captureFrame,
        window.PREVIEW_TIME * 1000
      );
      captureFrame();
      return () => clearInterval(captureInterval);
    } else if (appState === AppState.Reviewing) {
      const reviewInterval = setInterval(
        () =>
          setReviewFrame((curr) => {
            let newFrameIndex = curr + 1;
            if (newFrameIndex > captures.length - 1) {
              newFrameIndex = 0;
            }
            return newFrameIndex;
          }),
        window.REVIEW_TIME * 1000
      );
      setReviewFrame(0);
      return () => clearInterval(reviewInterval);
    } else if (appState === AppState.Uploading) {
      submit(phoneNumber, captures, () => setAppState(AppState.Done)).catch(
        (error: string) => console.error({ error })
      );
    } else if (appState === AppState.Done) {
      setTimeout(() => {
        setCaptures([]);
        setPhoneNumber("+1");
        setAppState(AppState.EnteringNumber);
      }, 5000);
    }
  }, [appState]);

  const onTouch: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    console.log({ event });
    const inX = event.clientX;
    const inY = event.clientY;

    const insetX = ((1 - scale) * window.innerWidth) / 2;
    const insetY = ((1 - scale) * window.innerHeight) / 2;

    const x = inX * scale + insetX;
    const y = inY * scale + insetY;

    const elements = document.elementsFromPoint(x, y);

    const button = elements.find(
      (e) => e.nodeName === "BUTTON" && !e.className.includes("touch_absorber")
    );
    if (button) {
      (button as HTMLButtonElement).click();
    }

    console.log({ inX, x, inY, y, elements });
  };

  // useEffect(() => {
  //   const main = document.querySelector("main");
  //   const root = document.querySelector("#root") as HTMLDivElement;

  //   if (!main || !root) return;

  //   main.style.transform = "scale(0.9)";
  //   main.style.width = "100vh";
  //   main.style.height = "100vh";

  //   root.style.transform = "scale(0.9)";
  //   root.style.width = "100vh";
  //   root.style.height = "100vh";
  // }, []);

  return (
    <>
      <div ref={containerRef} className={styles.booth}>
        <div ref={flashRef} className={styles.booth__flash} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zoratopia Photo Booth</title>
          <script type="text/javascript" src="./screenfull.min.js"></script>
          <script src="./p5.min.js"></script>
          <script src="./p5.dom.min.js"></script>
          <script src="./clmtrackr.min.js"></script>
          <script src="./sketch.js"></script>
        </Helmet>
        {(appState === AppState.Previewing ||
          appState === AppState.Capturing) && (
          <div className={styles.booth__bar}>
            <div className={styles.booth__bar_fill} />
            {appState === AppState.Previewing
              ? Array(window.SHADER_COUNT - 1)
                  .fill("")
                  .map((_, i) => (
                    <div
                      key={`shader-${i}`}
                      className={styles.booth__bar_dot}
                    />
                  ))
              : Array(window.SHADER_COUNT)
                  .fill("")
                  .map((_, i) => (
                    <div
                      key={`shader-${i}`}
                      className={styles.booth__bar_dot}
                      style={{
                        position: "relative",
                        left: `${((i + 1) / window.SHADER_COUNT) * 10}%`,
                        backgroundColor:
                          captures.length > i ? "purple !important" : "white",
                      }}
                    />
                  ))}
          </div>
        )}
        {appState === AppState.EnteringNumber && !window.IS_DEV && (
          <div
            className={styles.booth__content}
            data-background="true"
            data-no-center="true"
          >
            <img
              className={styles.booth__logo}
              src="./zoralogo.svg"
              alt="logo"
            />
            <span className={styles.booth__instructions}>
              Enter your number and take some pics! <br />
              We'll text them to you
            </span>
            <div className={styles.booth__number_field}>
              <div>
                <span>
                  {phoneNumber.length > 0 ? phoneNumber : "Enter your number"}
                </span>
              </div>
            </div>
            <div className={styles.booth__num_pad}>
              <div>
                {[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "<",
                  "0",
                  "+",
                ].map((num) => (
                  <button
                    key={`num-${num}`}
                    style={
                      num === "+" && phoneNumber.includes("+")
                        ? { opacity: 0, pointerEvents: "none" }
                        : {}
                    }
                    onClick={() =>
                      setPhoneNumber((curr) => {
                        if (num === "<") {
                          return curr.slice(0, Math.max(curr.length - 1, 0));
                        } else {
                          return curr + num;
                        }
                      })
                    }
                  >
                    <span>
                      {num === "<" ? (
                        <img src="./delete-icon.svg" alt="delete" />
                      ) : (
                        num
                      )}
                    </span>
                  </button>
                ))}
              </div>
            </div>
            <button
              className={styles.booth__cta}
              disabled={!numberIsValid}
              onClick={() => setAppState(AppState.Previewing)}
            >
              <div>
                <span>Begin</span>
              </div>
            </button>
          </div>
        )}
        {[AppState.Previewing, AppState.Reviewing].includes(appState) && (
          <div className={styles.booth__content}>
            {appState === AppState.Reviewing && (
              <>
                <img
                  className={styles.booth__review_image}
                  src={captures[reviewFrame]}
                  alt="preview"
                />
                <button
                  className={styles.booth__redo}
                  onClick={() => {
                    setCaptures([]);
                    setAppState(AppState.Previewing);
                  }}
                >
                  <div>
                    <span>Redo</span>
                  </div>
                </button>
              </>
            )}
            <button
              className={styles.booth__cta}
              onClick={() => {
                if (appState === AppState.Previewing) {
                  setAppState(AppState.Capturing);
                } else if (appState === AppState.Reviewing) {
                  setAppState(AppState.Uploading);
                }
              }}
            >
              <div>
                <span>
                  {appState === AppState.Previewing
                    ? "Press Here to Start"
                    : "Submit"}
                </span>
                <span>
                  {appState === AppState.Previewing &&
                    "(Don't worry, you'll have a sec)"}
                </span>
              </div>
            </button>
          </div>
        )}
        {appState === AppState.Uploading && (
          <div className={styles.booth__content} data-background="true">
            <h3>Uploading</h3>
            <p>Hang on just a sec...</p>
          </div>
        )}
        {appState === AppState.Done && (
          <div className={styles.booth__content} data-background="true">
            <h3>Done!</h3>
            <p>We're sending your photos now ;)</p>
          </div>
        )}
      </div>
      <button className={styles.booth__touch_absorber} onClick={onTouch} />
    </>
  );
};

export default Booth;

import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDHPcjle1MKEN0B0Xrv6lyRSR7ssuwJDhw",
  authDomain: "shader-booth.firebaseapp.com",
  projectId: "shader-booth",
  storageBucket: "shader-booth.appspot.com",
  messagingSenderId: "440315471936",
  appId: "1:440315471936:web:3a02a4d2ca379e228597a5",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);

const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const uploadFileToFolder = (
  file: Blob,
  path: string,
  filename: string,
  retryUpload: boolean = true
) => {
  let storageRef = ref(storage, `${path}/${`${filename}`}`);
  if (file) {
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on("state_changed", undefined, (error) => {
      console.error("Error uploading", error);
      if (retryUpload) {
        uploadFileToFolder(file, path, filename, false);
      } else {
        throw new Error("Couldn't upload a file");
      }
    });
    return uploadTask.then(() => getDownloadURL(uploadTask.snapshot.ref));
  }
};

export const submit = async (
  phoneNumber: string,
  captures: string[],
  onInitialCompletion: () => void
) => {
  const urls = await Promise.all(
    captures.map(async (capture: string, i) => {
      const blob = b64toBlob(capture.replace(/data:image\/png;base64,/, ""));
      const url = await uploadFileToFolder(
        blob,
        `${phoneNumber}-${new Date().getTime()}`,
        `capture-${i}.png`
      );
      return url as any as string;
    })
  );
  console.log({ urls });
  onInitialCompletion();

  const rawResponse = await fetch(
    `https://us-central1-shader-booth.cloudfunctions.net/processCompletedSubmission`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        urls,
      }),
    }
  );
  const content = await rawResponse.json();
  console.log({ content });
};

export const getSubmission = async (submissionId: string) => {
  const rawResponse = await fetch(
    `https://us-central1-shader-booth.cloudfunctions.net/getSubmission?id=${submissionId}`
  );
  const content = await rawResponse.json();
  console.log({ content });
  return content;
};

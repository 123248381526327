import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSubmission } from "../../client/firebase";

import styles from "./capture.module.scss";

interface Capture {
  gif: string;
  frames: string[];
}

const CapturePage: FC<{}> = ({}) => {
  const params = useParams();
  const [frames, setFrames] = useState<string[]>();
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    getSubmission(params.captureId || "").then((data) =>
      setFrames([data.gif, ...data.frames])
    );
  }, [params]);

  const download = useCallback(() => {
    const url = (frames || [])[selectedImage] || "";
    if (navigator.share) {
      navigator
        .share({
          url: url,
        })
        .catch(console.error);
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/")[url.split("/").length - 1];
      document.body.appendChild(link);
      link.click();
    }
  }, [frames, selectedImage]);

  return (
    <main className={styles.capture}>
      <img
        className={styles.capture__logo}
        src="../../zoralogo.svg"
        alt="logo"
      />
      {frames ? (
        <div className={styles.capture__container}>
          <div className={styles.capture__selection}>
            {frames && <img src={frames[selectedImage]} alt="Your gif!" />}
            <button onClick={download}>
              <h6>SHARE</h6>
            </button>
          </div>
          <div className={styles.capture__list}>
            {frames.map((frame, i) => {
              return (
                <button key={frame} onClick={() => setSelectedImage(i)}>
                  <img
                    src={frame}
                    alt={`Capture ${i}`}
                    data-selected={selectedImage === i}
                  />
                  {i === 0 && (
                    <div>
                      <h6>GIF</h6>
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.capture__container}>Loading...</div>
      )}
    </main>
  );
};

export default CapturePage;

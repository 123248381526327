import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.scss";
import "./assets/reset.scss";
import Booth from "./pages/booth/booth";
import Dev from "./pages/dev/dev";
import Capture from "./pages/capture/capture";
import Landing from "./pages/landing/landing";

window.IS_DEV = false;
window.PREVIEW_TIME = 2;
window.REVIEW_TIME = 1;
window.SHADER_COUNT = 5;
window.CYCLE_START = new Date();
window.APP_STATE = "previewing";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="booth" element={<Booth />} />
      <Route path="dev" element={<Dev />} />
      <Route path="capture/:captureId" element={<Capture />} />
    </Routes>
  </BrowserRouter>
);

window.onload = () => {
  const interval = setInterval(() => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.style.width = "100vw !important";
      canvas.style.height = "100vh !important;";
      clearInterval(interval);
    }
  }, 50);
};

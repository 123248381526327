import { FC } from "react";

import styles from "./landing.module.scss";

const Landing: FC<{}> = () => {
  return (
    <main className={styles.landing}>
      <img className={styles.landing__logo} src="../../logo.svg" alt="logo" />
    </main>
  );
};

export default Landing;
